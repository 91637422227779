import React, {useEffect, useState} from "react";
import {createUseStyles} from "react-jss";
import {Section} from "../Section/Section";
import {AppBar} from "../AppBar/AppBar";
import {BetaLogo} from "../logo/BetaLogo";
import {useProgressState} from "../progress/progressContext";
import {ChapterProgress} from "../progress/ChapterProgress";
import {useScrollytellState} from "../Admin/scrollytellContext";
import {useFontsUsed} from "../fonts/useFontsUsed";

const useStyles = createUseStyles(({colors}) => ({
    contentContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        zIndex: 0
    },
    content: {
        width: "100%",
        overflowY: "auto",
        scrollBehavior: "smooth",
        backgroundColor: colors.white,
        height: "calc(100% - 64px)",
        transition: "margin-left 150ms ease-in",
        scrollbarWidth: "none",
    },
    story: {
        position: ({isSticky}) => (isSticky ? "sticky" : "relative"),
        zIndex: 10,
    },
    "@global": {
        body: {margin: 0},
    },
}));

export const Story = ({
                          data: {isSticky, color, sections, appBar, about},
                          isAdminMode = false,
                          onSectionChange = () => {
                          },
                          onClose,
                          showSidePanel,
                      }) => {
    const classes = useStyles({
        color: color,
        isSticky: isSticky,
        isAdminMode: isAdminMode,
        showSidePanel: showSidePanel,
    })
    const [currentSection, setCurrentSection] = useState(null);
    const sortedSections = sections?.sort((a, b) => a.order - b.order);
    const {locks} = useProgressState()
    useFontsUsed({loadAll: isAdminMode})


    const findUnlockedSections = () => {
        const sectionsWithLock = sections?.map((section, sectionId) => ({
            ...section,
            id: sectionId,
            locked: Boolean(locks.find(lock => lock.sectionId === sectionId))
        }))

        const firstLockedSection = sectionsWithLock.find(section => section.locked)
        return isAdminMode ? sortedSections : sortedSections.slice(0, firstLockedSection?.id + 1 || sortedSections.length)
    }


    const exhibition = () => {
        if (about?.exhibition) {
            return about.exhibition
        } else {
            return appBar?.exhibition
        }
    }

    const museum = () => {
        if (about?.museum) {
            return about.museum
        } else {
            return appBar?.museum
        }
    }

    useEffect(() => {
        onSectionChange(currentSection);
    }, [currentSection]);
    return (
        <div className={classes.contentContainer}>
            <AppBar
                onClose={onClose}
                exhibition={exhibition()}
                museum={museum()}
                mode={appBar?.mode}
                backgroundColor={appBar?.backgroundColor}
                isAdminMode={isAdminMode}
            />
            <div className={classes.content} id="scrollytell-content">
                <BetaLogo
                    show={!isAdminMode}
                />
                <ChapterProgress
                    show={!isAdminMode}
                />
                <main className={classes.story}>
                    {findUnlockedSections()?.map((section, i) => (
                        <Section
                            key={`section-${i}`}
                            sectionId={i}
                            isAdminMode={isAdminMode}
                            section={section}
                            onChange={(sectionId) => setCurrentSection(sectionId)}
                        />
                    ))}
                </main>
            </div>
        </div>
    );
};
