import React from "react";
import {createUseStyles} from "react-jss";
import {Container} from "../Container/Container";
import {
    getBackgroundImageString,
    getBackgroundPositionByFocusPoint,
} from "../helpers/helpers";
import {GridHelper} from "./GridHelper/GridHelper";
import {
    SectionGridSize,
    SectionMinHeights,
    VerticalAlign,
} from "../helpers/enums";
import {useScrollytellState} from "../Admin/scrollytellContext";
import {LockBlock} from "../lock/LockBlock";
import {Blocks} from "../Block/Blocks";
import {LockContainer} from "../lock/LockContainer";
import {EditContainer} from "../Container/EditContainer";
import {EditLockContainer} from "../Container/EditLockContainer";
import {EditLockBlock} from "../lock/EditLockBlock";
import {BackgroundImage} from "../background/BackgroundImage";
import {useContentHeight} from "../map/useContentHeight";

const useStyles = createUseStyles({
    grid: {
        top: 0,
        position: "relative",
        minHeight: ({minHeight}) => minHeight,
        height: ({height}) => height,
        display: "grid",
        placeItems: ({verticalAlign}) => {
            if (verticalAlign === VerticalAlign.BOTTOM) {
                return "end stretch";
            } else if (verticalAlign === VerticalAlign.CENTER) {
                return "center stretch";
            } else {
                return "start stretch";
            }
        },
    },
    thumbnail: {
        minHeight: "initial !important",
        height: "350px",
        width: "700px",
        gridTemplateColumns: ({gridSize}) =>
            gridSize === SectionGridSize.NINE_COLUMNS
                ? "2% 2% 2% 2% 1fr 2% 2% 2% 2%"
                : "repeat(12, 1fr)",
    },
    nineColumns: {
        gridTemplateColumns: "2% 2% 2% 2% 1fr 2% 2% 2% 2%",
        "@media screen and (min-width: 800px)": {
            gridTemplateColumns: "1fr 1fr 1fr 1fr 700px 1fr 1fr 1fr 1fr",
        },
        "@media screen and(min-width: 650px)": {
            gridTemplateColumns: "1fr 1fr 1fr 1fr 600px 1fr 1fr 1fr 1fr",
        },
    },
    twelveColumns: {
        gridTemplateColumns: "2% 2% 2% 2% 1fr 2% 2% 2% 2%",
        "@media screen and (min-width: 1024px)": {
            gridTemplateColumns: "repeat(12, 1fr)",
        },
    }
});

const calculateMinHeight = ({minHeight, contentHeight}) => {
    switch (minHeight) {
        case SectionMinHeights.ONE_AND_HALF:
            return `${contentHeight * 1.5}px`;
        case SectionMinHeights.FULL:
            return `${contentHeight}px`;
        case SectionMinHeights.HALF:
            return `${contentHeight / 2}px`;
        case SectionMinHeights.QUARTER:
            return `${contentHeight / 4}px`;
        case SectionMinHeights.CONTENT:
            return minHeight
        default:
            return `${contentHeight}px`;
    }
}

export const ContentSection = ({
   sectionId,
   isAdminMode,
   section: {
       backgroundColor,
       gradient,
       height,
       backgroundImage,
       containers,
       gridSize = SectionGridSize.NINE_COLUMNS,
       verticalAlign,
       backgroundFocus,
       lock,
       minHeight=SectionMinHeights.FULL
   },
   isThumbnail,
}) => {
    const {showGrid} = useScrollytellState();
    const {backgroundPositionX, backgroundPositionY} =
        getBackgroundPositionByFocusPoint(backgroundFocus?.x, backgroundFocus?.y);

    const contentHeight = useContentHeight()

    const classes = useStyles({
        gradient,
        backgroundColor,
        backgroundImage,
        height,
        minHeight: calculateMinHeight({minHeight, contentHeight}),
        verticalAlign,
        backgroundPositionX,
        backgroundPositionY,
        gridSize,
    });

    const hasLock = Boolean(containers.find(container => container.blocks.find(block => block.locked === true)))

    const gridHelper = () => {
        if(showGrid) {
            return (
                <GridHelper
                    sectionId={sectionId}
                    gridSize={gridSize}
                    backgroundColor={backgroundColor}
                />
            )
        } else {
            return null
        }
    }

    return (
        <>
            {gridHelper()}
            <BackgroundImage
                backgroundImage={backgroundImage}
                positionX={backgroundPositionX}
                positionY={backgroundPositionY}
                gradient={gradient}
            />
            <article
                className={`${classes.grid} ${classes[gridSize]} ${
                    isThumbnail ? classes.thumbnail : ""
                }`}
            >
                {containers?.map((container, i) => (
                    <Container
                        key={`container-${i}`}
                        sectionId={sectionId}
                        containerId={i}
                        isAdminMode={isAdminMode}
                        gridSize={gridSize}
                        backgroundColor={container.backgroundColor}
                        startColumn={container.startColumn}
                        endColumn={container.endColumn}
                        horizontalAlign={container.horizontalAlign}
                        verticalAlign={container.verticalAlign}
                        borderRadius={container.borderRadius}
                        hasBlocks={Boolean(container.blocks?.length)}
                        isThumbnail={isThumbnail}
                    >
                        <EditContainer
                            sectionId={sectionId}
                            containerId={i}
                            isAdminMode={isAdminMode}
                            gridSize={gridSize}
                            container={container}
                        />
                        <Blocks
                            sectionId={sectionId}
                            containerId={i}
                            isAdminMode={isAdminMode}
                            blocks={container.blocks}
                        />
                    </Container>
                ))}
                <LockContainer hasLock={hasLock}>
                    <Container
                        key={`lock`}
                        sectionId={sectionId}
                        containerId={'lock'}
                        isAdminMode={isAdminMode}
                        gridSize={gridSize}
                        backgroundColor={lock?.backgroundColor}
                        startColumn={lock?.startColumn}
                        endColumn={lock?.endColumn}
                        horizontalAlign={lock?.horizontalAlign}
                        verticalAlign={lock?.verticalAlign}
                        borderRadius={lock?.borderRadius}
                        hasBlocks={true}
                        isThumbnail={isThumbnail}
                    >
                        <EditLockContainer
                            sectionId={sectionId}
                            containerId={'lock'}
                            isAdminMode={isAdminMode}
                            gridSize={gridSize}
                            container={lock}
                        />
                        <EditLockBlock
                            lock={lock}
                            sectionId={sectionId}
                            isAdminMode={isAdminMode}
                        >
                            <LockBlock
                                lock={lock}
                                backgroundColor={lock?.backgroundColor}
                                sectionId={sectionId}
                            />
                        </EditLockBlock>
                    </Container>
                </LockContainer>
            </article>
        </>
    );
};
