import React, {useState} from "react";
import {createUseStyles} from "react-jss";
import {GridHelper} from "./GridHelper/GridHelper";
import {StickyBlockContainer} from "../Container/StickyBlockContainer";
import {SectionGridSize, SectionMinHeights} from "../helpers/enums";
import {Scroller, Step} from "../Scroller";
import {useScrollytellState} from "../Admin/scrollytellContext";
import {MultiBackgrounds} from "../background/MultiBackgrounds";
import {SingleBackground} from "../background/SingleBackground";
import {BackgroundContainer} from "../background/BackgroundContainer";
import {Blocks} from "../Block/Blocks";
import {Container} from "../Container/Container";
import {EditLockContainer} from "../Container/EditLockContainer";
import {EditLockBlock} from "../lock/EditLockBlock";
import {LockBlock} from "../lock/LockBlock";
import {LockContainer} from "../lock/LockContainer";
import {useContentHeight} from "../map/useContentHeight";

const useStyles = createUseStyles({
    grid: {
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        minHeight: ({minHeight}) => minHeight,
        height: ({height}) => height,
        display: "grid",
        zIndex: 10,
    },
    thumbnail: {
        minHeight: "initial !important",
        height: "350px",
        width: "700px",
        gridTemplateColumns: ({gridSize}) =>
            gridSize === SectionGridSize.TWELVE_COLUMNS
                ? "repeat(12, 1fr)"
                : "2% 2% 2% 2% 1fr 2% 2% 2% 2%",
    },
    nineColumns: {
        gridTemplateColumns: "2% 2% 2% 2% 1fr 2% 2% 2% 2%",
        "@media screen and (min-width: 800px)": {
            gridTemplateColumns: "1fr 1fr 1fr 1fr 700px 1fr 1fr 1fr 1fr",
        },
        "@media screen and(min-width: 650px)": {
            gridTemplateColumns: "1fr 1fr 1fr 1fr 600px 1fr 1fr 1fr 1fr",
        },
    },
    twelveColumns: {
        gridTemplateColumns: "2% 2% 2% 2% 1fr 2% 2% 2% 2%",
        "@media screen and (min-width: 1024px)": {
            gridTemplateColumns: "repeat(12, 1fr)",
        },
    }
});

export const StickySection = ({
                                  sectionId,
                                  isAdminMode,
                                  section,
                                  isThumbnail,
                              }) => {
    const {
        backgroundColor,
        gradient,
        height,
        backgroundImage,
        containers,
        backgroundFocus,
        gridSize = SectionGridSize.NINE_COLUMNS,
        lock
    } = section;
    const {showGrid} = useScrollytellState();
    const contentHeight = useContentHeight()
    const minHeight = `${contentHeight}px`;
    const classes = useStyles({
        height,
        minHeight: minHeight,
        gridSize
    });
    const [currentStep, setCurrentStep] = useState(null);

    const onStepEnter = ({data: {i}}) => {
        setCurrentStep(i);
    };
    const onStepLeave = ({data: {i}, direction}) => {
        if (direction === "up") {
            setCurrentStep(i > 0 ? i - 1 : null);
        }
    };

    const singleOrMultiBackground = () => {
        if (typeof backgroundImage === "string") {
            return (
                <SingleBackground
                    gradient={gradient}
                    isAdminMode={isAdminMode}
                    backgroundImage={backgroundImage}
                    height={height}
                    backgroundColor={backgroundColor}
                    minHeight={minHeight}
                    backgroundFocus={backgroundFocus}
                />
            )
        } else if (backgroundImage instanceof Array) {
            return (
                <MultiBackgrounds
                    backgroundImage={backgroundImage}
                    currentStep={currentStep}
                    gradient={gradient}
                    contentHeight={contentHeight}
                />
            )
        } else {
            return null
        }
    }

    const hasLock = Boolean(containers.find(container => container.blocks.find(block => block.locked === true)))
    return (
        <>
            {showGrid && (
                <GridHelper
                    sectionId={sectionId}
                    gridSize={gridSize}
                    backgroundColor={backgroundColor}
                />
            )}
            <article
                className={`${classes.grid} ${classes[gridSize]} ${
                    isThumbnail ? classes.thumbnail : ""
                }`}
            >
                <Scroller
                    onStepEnter={onStepEnter}
                    onStepLeave={onStepLeave}
                    section={section}
                    containersCount={containers.length}
                    isThumbnail={isThumbnail}
                    offset={0.9}
                    contentHeight={contentHeight}
                >
                    {containers?.slice(0, isThumbnail ? 1 : containers?.length)
                        .map((container, i) => (
                        <Step data={{container, i}} key={i}>
                            <StickyBlockContainer
                                key={`container-${i}`}
                                sectionId={sectionId}
                                containerId={i}
                                isAdminMode={isAdminMode}
                                container={container}
                                gridSize={gridSize}
                                hasBlocks={Boolean(container.blocks?.length)}
                            >
                                <Blocks
                                    sectionId={sectionId}
                                    containerId={i}
                                    isAdminMode={isAdminMode}
                                    blocks={container.blocks}
                                />
                            </StickyBlockContainer>
                        </Step>
                    ))}
                </Scroller>
                <LockContainer hasLock={hasLock}>
                    <Container
                        key={`lock`}
                        sectionId={sectionId}
                        containerId={'lock'}
                        isAdminMode={isAdminMode}
                        gridSize={gridSize}
                        backgroundColor={lock?.backgroundColor}
                        startColumn={lock?.startColumn}
                        endColumn={lock?.endColumn}
                        horizontalAlign={lock?.horizontalAlign}
                        verticalAlign={lock?.verticalAlign}
                        borderRadius={lock?.borderRadius}
                        isThumbnail={isThumbnail}
                        hasBlocks={true}
                    >
                        <EditLockContainer
                            sectionId={sectionId}
                            containerId={'lock'}
                            isAdminMode={isAdminMode}
                            gridSize={gridSize}
                            container={lock}
                        />
                        <EditLockBlock
                            lock={lock}
                            sectionId={sectionId}
                            isAdminMode={isAdminMode}
                        >
                            <LockBlock
                                lock={lock}
                                backgroundColor={lock?.backgroundColor}
                                sectionId={sectionId}
                            />
                        </EditLockBlock>
                    </Container>
                </LockContainer>
            </article>
            <BackgroundContainer>
                {singleOrMultiBackground()}
            </BackgroundContainer>
        </>
    );
};
